import React, { useEffect } from "react";
// import { useColorMode } from "theme-ui";
const src = "https://utteranc.es/client.js";
const branch = "master";
// const DARK_THEME = "photon-dark";
// const LIGHT_THEME = "github-light";

export const Utterances = ({ repo }) => {
  const rootElm = React.createRef();
  // const rootElm = document.createElement( 'template' );
  // const [colorMode, setColorMode] = useColorMode();

  useEffect(() => {
    const utterances = document.createElement("script");
    const utterancesConfig = {
      src,
      repo,
      branch,
      // theme: colorMode === "dark" ? DARK_THEME : LIGHT_THEME,
      label: "comment",
      async: true,
      "issue-term": "pathname",
      crossorigin: "anonymous",
    };

    Object.keys(utterancesConfig).forEach((configKey) => {
      utterances.setAttribute(configKey, utterancesConfig[configKey]);
    });
    rootElm.current.innerHTML = "";
    rootElm.current.appendChild(utterances);
    // rootElm.current.insertAdjacentHTML('beforeend', utterances.outerHTML);
  });

  return (
    <>
      <div className="parent-utt">
        <div ref={rootElm}></div>
      </div>
    </>
  );
};
